import { render, staticRenderFns } from "./CompanyServices.vue?vue&type=template&id=40308526&scoped=true"
import script from "./CompanyServices.vue?vue&type=script&lang=js"
export * from "./CompanyServices.vue?vue&type=script&lang=js"
import style0 from "./CompanyServices.vue?vue&type=style&index=0&id=40308526&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40308526",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('40308526')) {
      api.createRecord('40308526', component.options)
    } else {
      api.reload('40308526', component.options)
    }
    module.hot.accept("./CompanyServices.vue?vue&type=template&id=40308526&scoped=true", function () {
      api.rerender('40308526', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Services/CompanyServices/CompanyServices.vue"
export default component.exports